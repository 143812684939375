import { useEffect, useState } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'
import { MdDelete } from 'react-icons/md'
import { MdArchive } from 'react-icons/md'

const Reports = () => {

	const { auth, setAuth } = useAuth()
	const reports = auth?.dashboard?.reports

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "numeric", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="reports">
				<h1>Reports</h1>
				{reports?.length ?
					<ul className="list">
						<li className="keys">
							<div className="type">Type</div>
							<div className="category">Category</div>
							<div className="report">Report</div>
							<div className="confirmed">Confirmed</div>
							<div className="opposed">Opposed</div>
							<div className="date">Date</div>
							<div className="buttons"></div>
						</li>
						{reports?.map((report, i) =>
							<li key={i}>
								<div className="type">{report.report_type}</div>
								<div className="category">{report.report_category}</div>
								<div className="report">{report.report}</div>
								<div className="confirmed">{report.confirmed}</div>
								<div className="opposed">{report.opposed}</div>
								<div className="date">{formatTimestamp(report.updated_at)}</div>
								<div className="buttons">
									<div className="archive"><MdArchive /></div>
									<div className="delete"><MdDelete /></div>
								</div>
							</li>
						)}
					</ul>
				:
					<p>No reports available</p>
				}
			</section>
		</>
	)
}

export default Reports