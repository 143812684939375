import { useState, useEffect, useRef } from 'react'
import useAuth from '../hooks/useAuth'
import useLogout from '../hooks/useLogout'
import { NavLink } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Menu = () => {

	const { auth, setAuth } = useAuth()
	const user = auth?.user
	const roles = auth?.roles
	const accessToken = auth?.accessToken

	const axiosPrivate = useAxiosPrivate();

	const exit = useLogout()

	const signOut = async () => {
		await exit()
		// navigate('/');
	}

	const refreshData = async () => {
		const dismiss = () =>  toast.dismiss(refreshDataToast.current);
		const refreshDataToast = toast.loading("Refreshing Console Data");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.get('get-dashboard',
			{
				signal: controller.signal
			});

			const dashboard = response?.data?.dashboard
			setAuth({ user, roles, accessToken, dashboard })

			// Update Toast Notification
			toast.update(refreshDataToast, { render: 'Console Data Refreshed', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(refreshDataToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(refreshDataToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(refreshDataToast, { render: 'Console Data Refresh Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const refreshCDOT = async () => {
		const dismiss = () =>  toast.dismiss(refreshCDOTToast.current);
		const refreshCDOTToast = toast.loading("Refreshing CDOT Data");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.get('refresh-data',
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(refreshCDOTToast, { render: 'CDOT Data Refreshed', type: 'success', isLoading: false, autoClose: 5000});

			refreshData()

		} catch (err) {
			if (!err?.response) {
				toast.update(refreshCDOTToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(refreshCDOTToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(refreshCDOTToast, { render: 'CDOT Data Refresh Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}


	return (
		<>
			<nav>
				<NavLink to="/overview">Overview</NavLink>
				<NavLink to="/notifications">Notifications</NavLink>
				<NavLink to="/maintenance">Maintenance</NavLink>
				<NavLink to="/reports">User Reports</NavLink>
				<NavLink to="/users">Users</NavLink>
				<NavLink to="/news">News</NavLink>
				<NavLink to="/popup">Popup</NavLink>
				<NavLink className="refresh" onClick={refreshData}>Refresh Console Data</NavLink>
				<NavLink className="refresh" onClick={refreshCDOT}>Refresh CDOT Data</NavLink>
				<NavLink className="logout" onClick={signOut}>Logout</NavLink>
			</nav>

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>
		</>
	)
}

export default Menu