import { useEffect, useState } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'
import { MdDelete } from 'react-icons/md'
import { MdArchive } from 'react-icons/md'

const Pass = () => {

	const { passname } = useParams();
	const location = useLocation();
	const { auth, setAuth } = useAuth()
	const dashboard = auth?.dashboard
	const status = auth?.dashboard?.status
	const reports = auth?.dashboard?.reports
	const notifications = auth?.dashboard?.notifications

	const [index, setIndex] = useState(location?.state?.index)

	const passDetails = status[index]
	console.log(passDetails)

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "numeric", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="pass">
				<div className="passOverviewWrapper">
					<h1>{passDetails.pass_name_clean}</h1>
					<h3>Last Updated: {formatTimestamp(passDetails?.updated_at)}</h3>
						<div className="passOverviewContainer">
							<div className="status"><strong>Status:</strong> {passDetails?.open ? 'Open' : 'Closed' }</div>
							<div className="statusOverride"><strong>Status Override:</strong> {passDetails?.open_override === null ? 'Inactive'
							: passDetails?.open_override ? 'Open': 'Closed' }</div>
							<div className="chainLaw"><strong>Chain Law:</strong> {passDetails?.chain_law ? 'Active' : 'No' }</div>
							<div className="tractionLaw"><strong>Traction Law:</strong> {passDetails?.traction_law ? 'Active' : 'No' }</div>
						</div>
				</div>
				<div className="reports">
					<h4>Reports</h4>
					{reports?.length ?
						<ul className="list">
							<li className="keys">
								<div className="type">Type</div>
								<div className="category">Category</div>
								<div className="report">Report</div>
								<div className="confirmed">Confirmed</div>
								<div className="opposed">Opposed</div>
								<div className="date">Date</div>
								<div className="buttons"></div>
							</li>
							{reports?.map((report, i) =>
								<li key={i}>
									<div className="type">{report.report_type}</div>
									<div className="category">{report.report_category}</div>
									<div className="report">{report.report}</div>
									<div className="confirmed">{report.confirmed}</div>
									<div className="opposed">{report.opposed}</div>
									<div className="date">{formatTimestamp(report.updated_at)}</div>
									<div className="buttons">
										<div className="archive"><MdArchive /></div>
										<div className="delete"><MdDelete /></div>
									</div>
								</li>
							)}
						</ul>
					:
						<p>No reports available</p>
					}
				</div>
			</section>
		</>
	)
}

export default Pass